.portfolioListDetail {
  padding: 5%;
  background-color: #ccffff;
  margin: 2% 5% 5% 5%;
  box-shadow: 4px 7px 9px #c4bfc0;
  animation: discoColors 5s linear 2s infinite alternate;
}

@keyframes discoColors {
  0% {
    background-color: #ccffff;
  }
  25% {
    background-color: #ccffff;
  }
  50% {
    background-color: #ffcc9a;
  }
  75% {
    background-color: #ccffcc;
  }
  100% {
    background-color: #ffffcc;
  }
}

.detailTags {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
}

.detailTags li {
  display: flex;
  border: 1px solid #00000020;
  background-color: #00000010;
  padding: 7px 15px;
  border-radius: 30px 30px 30px 30px;
  margin: 0 10px 10px 0;
  width: max-content;
  text-transform: lowercase;
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.portfolioListDetailContent,
.portfolioListDetail .alignLeft,
.portfolioListDetail .alignRightNow {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  margin-block-end: 0.67em;
}

.portfolioListDetail h1 {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.tagComponent {
  margin: 5%;
}
.tagComponent h2 {
  font-size: 1.2em;
  border-bottom: 1px solid #3b6a50;
}
.tagComponent p {
  font-style: italic;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
}

.tagContainer .tagItems {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: 10px;
  box-shadow: 2px 4px 5px #c4bfc0;
  animation: popUp 1s ease-in-out;
}

@keyframes popUp {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.tagItems .pItemName {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2em;
  margin-bottom: 5px;
  word-break: break-all;
}

.tagItems .pItemBlurb {
  font-size: 1em;
}

.tagItems a,
.tagItems a:hover,
.tagItems a:focus,
.tagItems a:active {
  text-decoration: none;
  color: #000;
  font-style: italic;
  text-align: right;
  padding-top: 10px;
  padding-left: 20px;
}

.tagItems:nth-child(5n + 1) {
  background-color: #9accff;
}

.tagItems:nth-child(5n + 2) {
  background-color: #ccffff;
}
.tagItems:nth-child(5n + 3) {
  background-color: #ffcc9a;
}
.tagItems:nth-child(5n + 4) {
  background-color: #ccffcc;
}
.tagItems:nth-child(5n + 5) {
  background-color: #ffffcc;
}
.portfolioDetailSection {
  display: flex;
  flex-direction: row;
  flex: 1;
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.portfolioDetailLeft button {
  background: #00000036;
  border: none;
  font-size: 1em;
  padding: 15px;
  font-style: italic;
  width: 100%;
  margin-bottom: 10px;
}

.portfolioDetailRight {
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}

.portfolioDetailRight span {
  padding-bottom: 5px;
}

@media screen and (max-width: 586px) {
  .portfolioDetailSection {
    display: flex;
    flex-direction: column;
  }
}

.tagContainer > div {
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .tagContainer > div {
    max-width: 29%;
    margin-bottom: 10px;
  }
  .portfolioDetailLeft button {
    width: auto;
  }
}
