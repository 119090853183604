* {
  font-family: "Roboto", sans-serif;
}
h1,
h2 {
  font-family: "Montserrat", sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav ul {
  display: flex;
  flex-direction: row;
  margin: 10px 40px 0 40px;
  padding: 0;
}
nav ul li {
  list-style: none;
  flex: 1;
}
nav ul li:nth-child(2) {
  text-align: right;
}

nav ul li a,
nav ul li a:active,
nav ul li a:hover,
nav ul li a:visited {
  text-decoration: none;
  color: #3b6a50;
}

nav ul li .fa {
  font-size: 30px;
}
