.errorMessage {
  color: red;
}

.portfolioDetail {
  border: 1px solid blue;
}

.portfolioList {
  display: flex;
  flex-wrap: wrap;
  padding: inherit;
  align-items: center;
  justify-content: center;
}

.portfolioListItem {
  box-shadow: 4px 7px 9px #c4bfc0;
}

@media screen AND (max-width: 767px) {
  .portfolioListItem {
    width: 90%;
    padding: 10px;
    margin: 10px;
  }
}

@media screen AND (min-width: 768px) AND (max-width: 990px) {
  .portfolioListItem {
    width: 43%;
    padding: 15px;
    margin: 15px;
  }
}

@media screen AND (min-width: 991px) {
  .portfolioListItem {
    width: 25%;
    padding: 20px;
    margin: 20px;
  }
}

.portfolioListItem div,
.portfolioListDetail div {
  display: flex;
}
.portfolioListItem > div:nth-child(3) {
  margin-top: 5%;
}

.portfolioListDetail .heading,
.portfolioListItem .heading {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-top: 0;
}
.portfolioListDetail .heading {
  font-size: 2em;
}

.portfolioListItem .smallCase {
  font-size: 0.9em;
}

.portfolioListDetail .alignLeft,
.portfolioListItem .alignLeft {
  text-align: left;
}

.portfolioListDetail .alignRightNow,
.portfolioListItem .alignRightNow {
  align-items: center;
  display: flex;
  text-align: right;
  margin-left: auto;
}

.portfolioListItem a {
  text-decoration: none;
}

.portfolioListItem:nth-child(5n + 1) {
  background-color: #9accff;
}

.portfolioListItem:nth-child(5n + 2) {
  background-color: #ccffff;
}
.portfolioListItem:nth-child(5n + 3) {
  background-color: #ffcc9a;
}
.portfolioListItem:nth-child(5n + 4) {
  background-color: #ccffcc;
}
.portfolioListItem:nth-child(5n + 5) {
  background-color: #ffffcc;
}

.alignRightNow a,
.alignRightNow a:hover,
.alignRightNow a:focus,
.alignRightNow a:active {
  font-style: italic;
  color: #3b6a50;
}

.alignLeft button {
  border: none;
  background-color: #00000012;
  padding: 10px;
  border-radius: 5%;
}
